export type Nen3140 = Extract<Ticket, { type: TicketType.NEN3140 }>
export type Nen1010 = Extract<Ticket, { type: TicketType.NEN1010 }>

export type CreateNen3140Payload = Extract<CreateTicketPayload, { type: TicketType.NEN3140 | TicketType.NEN1010 }>
export type CreateNen1010Payload = CreateNen3140Payload

export const useNen3140Store = defineStore("nen3140", () => {
  const baseUrl = "/tickets/nen3140s"

  const create = async (ticketData: CreateNen3140Payload): Promise<Nen3140> => {
    const { data: inspection } = await useSamApi<Nen3140>(baseUrl, {
      method: "POST",
      body: JSON.stringify({ ticketData }),
    })
    return inspection
  }

  const update = async (id: string, data: Nen3140Data): Promise<Nen3140> => {
    const { data: response } = await useSamApi<Nen3140>(`${baseUrl}/${id}`, { method: "PUT", body: JSON.stringify(data) })
    return response
  }

  return {
    create,
    update,
  }
})
